<template>
	<div class="row">
		<div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Configure as marcas disponíveis no sistema: adicione, atualize ou delete marcas, facilitando em diversos momentos a pesquisa de produtos.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

      <div class="col-12">
			<div class="card mb-2">
            <div class="card-body">
               <div class="row">
                  <div class="col">
							<label><i class="far fa-bookmark color-theme font-12 me-1"></i> Filtrar</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus placeholder="Busque por nome da marca" />
                  </div>
                  <div class="w-max-content align-self-end" v-if="dadosUsuario.usuarioPermissoes.includes('Produtos | Editar marcas')">
							<button type="button" class="btn btn-primary w-100 px-sm-5" @click="abrirModal(null)"><i class="far fa-plus font-13 me-1"></i> Adicionar</button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 px-0" v-if="pesquisa.resultado.length > 0">
			<div class="row marcas">
				<marca v-for="(marca, index) in pesquisa.resultado" :key="index" :marca="marca" :index="index" @editar="abrirModal($event)" />
			</div>
      </div>

      <!-- modalMarca -->
      <div class="modal fade" id="modalMarca" tabindex="-1" aria-labelledby="modalMarcaLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalMarcaLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} marca {{ modal.id == null ? '' : ('('+ modal.nome +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<div class="row">
									<div class="col-12 mb-3">
										<label><i class="far fa-bookmark color-theme font-12 me-1"></i> Nome da marca *</label>
										<input type="text" class="form-control" v-model="modal.nome" maxlength="200" />
									</div>

									<uploadImagem :resultado="modal.banner" label="Banner" widthCropper="col-12" @set="modal.banner = $event;" />
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-primary" @click="salvarMarca">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import uploadImagem from '@/components/modelos/UploadImagem.vue'
import marca from '@/components/produtos/marcas/Marca.vue'

export default {
	name: 'Marcas',
	data: function () {
		return {
			pesquisa: {'nome': null, 'resultado': []},
			modal: {'id': null, 'nome': null, 'banner': null},
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function () {
			this.buscarMarcas()
      },
      'pesquisa.nome' : function (val) {
         var value = val.toLowerCase()

         $(".row.marcas > *").filter(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      }
   },
	components: {
		marca, uploadImagem
	},
	methods: {
		abrirModal : function (marca) {
			if (marca == null) {
				Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			} else {
				this.modal = {'id': marca.id, 'nome': marca.nome, 'banner': marca.banner}
			}

			$('#modalMarca').modal('show')
		},
		buscarMarcas : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			this.$store.dispatch('getMarcas').then((data) => {
				this.pesquisa.resultado = data
			}).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
		},
		salvarMarca : function () {
			this.$store.dispatch('base64toFile', Array.of(this.modal.banner)).then((banner) => {
				if (this.modal.nome == null || String(this.modal.nome).trim().length == 0) {
					this.$toast.fire({
						icon: 'error',
						title: 'Preencha todos os campos obrigatórios'
					});

					return
				}

				this.$store.dispatch('alternarTelaCarregamento', true)

				let dataForm = new FormData();
				dataForm.append('info', JSON.stringify(this.modal));

				if (banner[0] instanceof File) {
					dataForm.append('banner', banner[0], String(banner[0].name).replace(/[^\w\s.]/gi, ''));
				}

				this.$axios({
					method: 'post',
					url: this.urlRest +'produtos/saveMarca',
					headers: {'Content-Type': 'multipart/form-data'},
					data: dataForm

				}).then(() => {
					$('#modalMarca').modal('hide')
					this.buscarMarcas()

					this.$toast.fire({
						icon: 'success',
						title: 'Marca salva!'
					});
				}).catch((error) => {
					if (error.response != undefined) {
						if (error.response.status == 408) {
							this.$store.dispatch('deslogar')

							this.$toast.fire({
								icon: 'warning',
								title: 'Sessão expirada!'
							});
						} else if (error.response.status == 409) {
							this.$toast.fire({
								icon: 'error',
								title: 'Nome da marca já existente!'
							});
						} else {
							this.$toast.fire({
								icon: 'error',
								title: 'Erro: ' + error.response.status
							});
						}
					}
				}).finally(() => {
					this.$store.dispatch('alternarTelaCarregamento', false)
				});
			})
		}
	},
	mounted() {
      this.buscarMarcas()
	}
}

</script>